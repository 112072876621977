/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import '~bootstrap/scss/functions';
@import './assets/styles/settings/bootstrap.variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// Font awesome
// @import '~@fortawesome/fontawesome-free/scss/variables';
@import './assets/styles/settings/font-awesome.variables';
@import './assets/styles/settings/font-awesome/mixins';
@import './assets/styles/settings/font-awesome/core';
@import './assets/styles/settings/font-awesome/larger';
@import './assets/styles/settings/font-awesome/fixed-width';
@import './assets/styles/settings/font-awesome/list';
@import './assets/styles/settings/font-awesome/bordered-pulled';
@import './assets/styles/settings/font-awesome/animated';
@import './assets/styles/settings/font-awesome/rotated-flipped';
@import './assets/styles/settings/font-awesome/stacked';
@import './assets/styles/settings/font-awesome/icons';
@import './assets/styles/settings/font-awesome/screen-reader';


html, body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $gray-100;
}

// Custom
// Settings
// Reboot
@import './assets/styles/reboot/fonts';
// Layout
@import './assets/styles/layouts/root';
@import './assets/styles/layouts/header';
@import './assets/styles/layouts/footer';
@import './assets/styles/layouts/drawer';
@import './assets/styles/layouts/areas';
@import './assets/styles/layouts/rows';
// Components
@import './assets/styles/components/forms';
// States

