// Notifications

@import '~bootstrap/scss/functions';
@import '../../../assets/styles/settings/bootstrap.variables';

$zindex-notification:               1080 !default;

.notifications {
  position: fixed;
  top: 0; right: 0;
  margin: $grid-gutter-width/2;
  z-index: $zindex-notification;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 320px;
    min-width: 280px;

    background-color: $white;
    border-radius: $grid-gutter-width/8;
    box-shadow: $box-shadow-sm;

    color: $dark;

    padding: $grid-gutter-width/4 $grid-gutter-width/2;

    line-height: 1.4;

    .btn {
      margin-top: -$grid-gutter-width/8;
      margin-right: -$grid-gutter-width/4;
    }

    & + .notifications-item {
      margin-top: $grid-gutter-width/4;
    }

    &.is-success,
    &.is-warning,
    &.is-info,
    &.is-danger {
      color: $white;
      .btn {
        color: $white;
      }
    }

    &.is-success {
      background-color: $success;
    }
    &.is-warning {
      background-color: $warning;
    }
    &.is-info {
      background-color: $info;
    }
    &.is-danger {
      background-color: $danger;
    }
  }
}