// Layouts: Rows

.row-16 {
  margin-right: -$grid-gutter-width/4;
  margin-left: -$grid-gutter-width/4;
  & > [class^="col-"] {
    padding-right: $grid-gutter-width/4;
    padding-left: $grid-gutter-width/4;
  }
}

.row-8 {
  margin-right: -$grid-gutter-width/8;
  margin-left: -$grid-gutter-width/8;
  & > [class^="col-"] {
    padding-right: $grid-gutter-width/8;
    padding-left: $grid-gutter-width/8;
  }
}
