// Layouts: drawer

.root-drawer {
  width: $grid-gutter-width*1.9;

  background-color: $white;
  border-right: 1px solid $gray-300;

  margin-top: -$grid-gutter-width*1.75;

  // transition: width .3s ease-in-out;
  &.is-open {
    width: $grid-gutter-width*8;
  }

  &-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: $grid-gutter-width*1.75;

    border-bottom: 1px solid $gray-300;

    padding-right: $grid-gutter-width/2;
    padding-left: $grid-gutter-width/2;
  }
  &-middle {
    padding-top: $grid-gutter-width/4;
  }
}

.nav-drawer {
  overflow: hidden;
  .nav-item {
    width: 100%;
  }
    button.nav-link {
      border: 0;
      background-color: transparent;
      background-image: none;

      width: 100%;

      color: $primary;
    }
    .nav-link {
      display: flex;
      padding: .5rem;
      &.is-active {
        background-color: $primary;
        color: $white;
      }
      &-icon {
        flex-shrink: 0;

        width: $grid-gutter-width*1.25;

        text-align: center;
      }
      &-text {
        flex-grow: 1;

        margin-left: .75rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        text-align: left;
      }
    }
}
