@import '../settings/font-awesome.variables';

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
  url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}

// .fab {
//   font-family: 'Font Awesome 5 Brands';
// }

// .far {
//   font-family: 'Font Awesome 5 Free';
//   font-weight: 400;
// }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}